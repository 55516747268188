import React from 'react'

// Components
import ServicesBlock from 'components/services-block'

function FlutterServices() {
  return (
    <ServicesBlock
      title={
        <span>
          <span className="has-text-purple">Flutter apps</span> for phones,
          tablets, web and IoT
        </span>
      }
      subtitle="Flutter enables organisations to offer customers a fast and fluid digital experience on all platforms, and is used by BMW, Sony and Toyota."
      items={[
        {
          title: 'iOS Apps',
          text: 'Create robust, high-quality Flutter apps that run on iPads or iPhones to reach 50.4% of your UK smartphone audience.',
        },
        {
          title: 'Android Apps',
          text: 'Reach 49.6% of the UK smartphone users by developing Flutter apps for Samsung, Sony and Google devices.',
        },
        {
          title: 'Desktop Apps',
          text: 'Create Flutter apps that run on Windows, Linux and Mac computers. Due to leave BETA in 2021.',
        },
        {
          title: 'Web Apps',
          text: "Flutter web won't replace conventional web apps, but it's a perfect way to get more 'bang-for-buck'.",
        },
        {
          title: 'Push Notifications',
          text: 'Achieve 60% open rates and keep customers up-to-date with progress, alerts and offers.',
        },
        {
          title: 'Devices & Instruments',
          text: 'Speak to us about the pros and cons of using Flutter for embedded devices in comparison to QT and Python.',
        },
        {
          title: 'Location and Mapping',
          text: 'Show the most relevant data based on where customers are. Useful for product search, place lookups and marketing.',
        },
        {
          title: 'Beacons',
          text: 'Influence customer behaviour when they are near your premises or improve your in-store experience.',
        },
        {
          title: 'Biometrics',
          text: 'Add extra security of your app without compromising convenience. Perfect for employee or customer facing apps.',
        },
        {
          title: 'Security Testing',
          text: 'Have your app PEN tested by our security partners to ensure your sensitive data is protected from cyber threats.',
        },
        {
          title: 'IoT Connectivity',
          text: 'Give customers control and visibility of your smart devices and instruments using Bluetooth or WiFi Connect.',
        },
        {
          title: 'Integrated with APIs',
          text: 'Connect your apps to CRM, CMS, ERP or your bespoke back-office systems. Store data offline for mobile working.',
        },
      ]}
    />
  )
}

export default FlutterServices
