/* eslint-disable react/prop-types */
import React from 'react'
import { graphql } from 'gatsby'
import ServicesFlutterAppDevelopment from 'routes/services/flutter-app-development'

// Meta Component
import Meta from 'components/Meta/Meta'

// ServicesFlutterAppDevelopment Route
export default ServicesFlutterAppDevelopment

// Gatsby SEO Head
export function Head({ data }) {
  return <Meta tags={data.page.seoMetaTags.tags} />
}

export const query = graphql`
  query ServicesFlutterAppDevelopmentQuery {
    page: datoCmsPage(slug: { eq: "services/flutter-app-development" }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      content
      featuredMedia {
        gatsbyImageData(
          placeholder: TRACED_SVG
          imgixParams: { fm: "jpg", auto: "compress", maxW: 600 }
        )
      }
    }
    testimonials: allDatoCmsClientTestimonial(limit: 2) {
      edges {
        node {
          id
          clientName
          clientRole
          content
          clientCompany
          isFlipped
          caseStudy {
            slug
          }
          clientLogo {
            gatsbyImageData(
              placeholder: TRACED_SVG
              imgixParams: { fm: "jpg", auto: "compress" }
            )
          }
          clientImage {
            gatsbyImageData(
              placeholder: TRACED_SVG
              imgixParams: { fm: "jpg", auto: "compress" }
            )
          }
        }
      }
    }
    posts: allDatoCmsArticle(
      limit: 3
      filter: {
        slug: {
          in: [
            "what-is-flutter"
            "morning-google-flutter-team"
            "experiments-with-flutter-and-figma"
          ]
        }
      }
      sort: { fields: [date], order: DESC }
    ) {
      edges {
        node {
          id
          title
          date(formatString: "MMMM D, YYYY")
          slug
          excerpt
          isFeatured
          featuredMedia {
            gatsbyImageData(
              placeholder: TRACED_SVG
              imgixParams: {
                w: "640"
                h: "480"
                maxW: 800
                fit: "crop"
                fm: "jpg"
                auto: "compress"
              }
            )
          }
          author {
            name
            slug
            mainImage {
              gatsbyImageData(
                placeholder: TRACED_SVG
                imgixParams: {
                  fm: "jpg"
                  auto: "compress"
                  fit: "facearea"
                  facepad: 3.0
                  maxW: 200
                }
              )
              url
            }
          }
          categories {
            name
            slug
          }
        }
      }
    }
  }
`
