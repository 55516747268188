import PropTypes from 'prop-types'
import React from 'react'

// Components
import SiteNav from 'components/navbar'
import Layout from 'components/layout'
import Testimonials from 'components/testimonials'
import Clients from 'components/clients'
import Customers from 'components/customers'
import Objectives from 'components/objectives'
import LeeQuote from 'components/leeQuote'

// Page Components
import FlutterHero from './components/flutter-hero'
import Stats from './components/stats'
import FlutterServices from './components/flutter-services'
import FlutterBlog from './components/flutter-blog'
import FlutterBenefits from './components/flutter-benefits'

function ServicesFlutterAppDevelopment({
  data: { page, testimonials, posts },
}) {
  return (
    <Layout>
      <SiteNav fullLogo />
      <FlutterHero page={page} />
      <Stats />
      <FlutterServices />
      <Testimonials testimonials={testimonials} />
      <FlutterBlog posts={posts} />
      <FlutterBenefits />
      <Clients />
      <Customers />
      <Objectives />
      <LeeQuote />
    </Layout>
  )
}

ServicesFlutterAppDevelopment.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ServicesFlutterAppDevelopment
