import PropTypes from 'prop-types'
import React from 'react'
import { Container, Hero } from 'react-bulma-components'
import { GatsbyImage } from 'gatsby-plugin-image'

function FlutterHero({ page }) {
  return (
    <Hero className="is-small">
      <Hero.Body>
        <Container className="content">
          <div className="columns is-tablet is-vcentered">
            <div className="column is-6 is-offset-1">
              <h1 className="title">Flutter App Development</h1>
              <p className="is-size-4">
                Reduce your time-to-market and reach your audience wherever they
                are with Flutter. We are a UK Flutter app development agency,
                working with ambitious leaders to modernise their customer
                experience.
              </p>
            </div>
            <div className="column is-4">
              <GatsbyImage
                image={page.featuredMedia.gatsbyImageData}
                alt="Flutter App Development - Pocketworks"
              />
            </div>
          </div>
        </Container>
      </Hero.Body>
    </Hero>
  )
}
FlutterHero.propTypes = {
  page: PropTypes.object,
}
export default FlutterHero
