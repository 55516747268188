import React from 'react'
import { Section, Columns, Container } from 'react-bulma-components'

function FlutterBenefits() {
  return (
    <Section>
      <Container>
        <Columns className="is-centered content is-hidden">
          <Columns.Column className="is-8-desktop is-10-tablet">
            <h2 className="has-text-centered">
              <span className="has-text-success">Is Flutter </span> right for
              you?
            </h2>
            <p>
              Flutter is a relative newcomer to the app development world,
              launched by Google in 2018. The question is, should you use it?
            </p>
            <p>
              From a business perspective, there are some advantages and
              disadvantages. Let's start with the pros.
            </p>
            <p>
              If you build with Flutter, there is a good chance a single
              developer can work on one app, which will then run on iOS and
              Android. This means you can reach a wider audience with less work.
              There are other frameworks that do this, such as Xamarine, React
              Native and native iOS and Android development
            </p>
            <p>
              So you are likely to see time-to-market and cost benefits if using
              Flutter. I would take this with a pinch of salt though. This is
              because there are so many factors that influence the cost and
              success of a mobile app development project. Flutter can help
              reduce cost by up to 30%, but don't make this your sole deciding
              factor for chosing the technology.{' '}
            </p>
            <p>
              Another reason to use Flutter is that it has a bold vision to
              offer an excellent user experience. On top of that, a single
              Flutter app can be designed to run on phones, desktop and even
              embedded touchscreens - like those in your car.
            </p>
            <p>
              Now for the downsides. Flutter can be abused, meaning that you can
              build things that don't feel like apps. So it's important to pick
              an app development company that also understands native mobile.{' '}
            </p>
          </Columns.Column>
        </Columns>
      </Container>
    </Section>
  )
}

export default FlutterBenefits
